import { cookies } from '@/configuration'

export const trackMonetate = (pType) => ({ store, monetate }) => {
  const membershipTier = getMembershipTier()
  const cartItems = store.getters['cart/cartItems']

  if (cartItems && pType !== 'DonationStart') {
    const cartRow = []
    for (let i = 0; i < cartItems.length; i++) {
      const skuCode = getNormalizedSkuCode(cartItems[i].sku)
      cartRow.push({
        productId: skuCode,
        quantity: cartItems[i].quantity,
        unitPrice: cartItems[i].total.amount,
        sku: skuCode,
        currency: cartItems[i].total.currencyCode
      })
    }
    monetate.trackView({ pType, membershipTier, cartRow })
  } else {
    monetate.trackView({ pType, membershipTier })
  }
}

export const trackMnConfirmationPage = (pType) => ({ store, monetate }) => {
  const membershipTier = getMembershipTier()
  const orderId = store.getters['order/orderId']
  const cartItems = store.getters['order/cartItems']
  const orderRow = []
  for (let i = 0; i < cartItems.length; i++) {
    const skuCode = getNormalizedSkuCode(cartItems[i].sku)
    orderRow.push({
      purchaseId: orderId,
      productId: skuCode,
      quantity: cartItems[i].quantity,
      unitPrice: cartItems[i].total.amount,
      sku: skuCode,
      currency: cartItems[i].total.currencyCode
    })
  }
  monetate.trackView({ pType, membershipTier, orderRow })
}

const getNormalizedSkuCode = (sku) => {
  const skuCode = sku.substring(0, 32)
  return skuCode
}

const getMembershipTier = () => {
  let membershipTier = ''
  if (cookies.userInfo.tier != null) {
    membershipTier = cookies.userInfo.tier
  } else {
    membershipTier = 'ANONYMOUS'
  }
  return membershipTier
}

export const trackMnLanding = (store, monetate) => trackMonetate('DonationStart')({ store, monetate })

export const trackMnPaymentOption = trackMonetate('DonationPayment')
export const trackMnPaymentCreditCard = trackMonetate('DonationCreditCard')
export const trackMnPaymentWalletCreditCard = trackMonetate('DonationWalletCreditCard')
export const trackMnPaymentBanking = trackMonetate('DonationBanking')
export const trackMnPaymentAmazonPay = trackMonetate('DonationAmazon')
export const trackMnPayPalView = trackMonetate('DonationPayPal')
export const trackMnApplePayView = trackMonetate('DonationApplePay')
export const trackMnConfirmation = trackMnConfirmationPage('DonationConfirmation')
export const trackMnSignIn = trackMonetate('DonationSignIn')
